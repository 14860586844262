.content-max{
  max-width: $maxWidth;
}

// .ant-form-item{
//   margin-bottom: 10px;
// }

.site-layout .site-layout-background {
  background: $white;
}

.logo-top{
  padding: 17px 25px;
  transition: all .67s ease-in-out;

  img{
    transition: all .67s ease-in-out;
    display: block;
    width: 100%;
    max-width: 300px;
    max-height: 30px;
  }
}

.site-header{
  background: $white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px !important;
}

.signature-canvas{
  width: 300px;
  height: 150px;
  margin: 40px 0;
  display: block;
  border: 1px solid rgba($black, .2);
  border-radius: 3px;
  position: relative;

  .btn{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.pending-page{
  width: 100%;
  padding: 20px;

  img{
    display: block;
    width: 100%;
    max-width: 250px;
    object-fit: contain;
    object-position: center;
    margin: 40px auto;
  }

  h1{
    margin: 20px auto;
    text-align: left;
    font-size: 2.000rem;
  }

  p{
    font-size: 16px;
  }

  .pending-icon{
    font-size: 42px;
    color: $primary;
  }
}

.site-page-header{
  margin: 0 0 20px 0 !important;
  padding: 0 !important;
}

.loading-global{
  width: 100%;
  max-height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .loading-icon{
    font-size: 34px;
    color: rgba($black, .2);
  }
}