.signin{
  width: 100%;
  height: 100vh;
  max-width: unset;
  margin: 0;
  padding: 0;

  .signin-bgr{
    width: 50%;
    display: flex;
    height: 100vh;
  }

  .signin-form{
    width: 50%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px;

    .logo{
      display: block;
      height: 35px;
      object-fit: contain;
      object-position: center;
    }

    .form-container{
      width: 100%;
      max-width: 400px;
    }
  }
}