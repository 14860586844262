.player{
  width: 100%;
  margin: 20px auto;
  border: 1px solid rgba($black, .1);
  flex-wrap: wrap;
  border-radius: 4px;
  background: $white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .player-invisible{
    display: none;
  }

  .controls{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .meta{
      margin-left: 20px;
      .title{
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $black;
        display: block;
        margin: 0;
      }

      .subtitle{
        font-size: 13px;
        line-height: 120%;
        font-weight: 500;
        color: rgba($black, .4);
        display: block;
        margin: 0;
      }
    }
  }

  .cover{
    width: 40px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid rgba($black, .1);
    margin-left: 20px;
    display: block;
    object-fit: cover;
    object-position: center;
  }
}