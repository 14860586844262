@import './helpers/bootstrap';

// Core color scheme
$black: #000000;
$white: #ffffff;

// Animation
$coreTransition: all .2s ease-in-out;

// Img
$heroDashboard: url('img/hero-dashboard.jpg');

// Vars
$maxWidth: 1328px;

body, html {
  font-size: 100%;
  font-smooth: auto;
  margin: 0;
  padding: 0;
}

*{
  box-sizing: border-box;
}

.ant-descriptions-item-label{
  font-weight: 500 !important;
  color: $black !important;
}

.guide-modal-container{
  width: 100%;

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe, object, embed{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// Core components
@import './components/core';
@import './components/signin';
@import './components/tracks';
@import './components/player';
@import './components/doc';
@import './components/dashboard';
@import './components/contract';
@import './components/descriptions';