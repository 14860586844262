.tracks-page{

  .tracks-table{
    .track-img{
      display: block;
      width: 100%;
      max-width: 50px;
      margin: 0;
      object-fit: contain;
      object-position: center;
    }
  }

}