.contract-view{

  size: A4;
  max-width: 1200px;
  padding: 50px;
  margin: 0 auto;
  text-align: justify;

  ol, ul{
    padding-left: 20px;
  }

  .contract-button-row{
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    background: rgba($white, .9);
  }

  @media print {
    margin: 0;
    padding: 0;
    max-width: unset;
    div, table {
      break-inside: avoid;
    }
    html, body {
      width: 210mm;
      height: 297mm;
      margin: 25mm;
      font-size: 11px;
    }

    .contract-button-row{
      display: none;
    }
  }

  table { table-layout: fixed; width: 100%; }

  .contract-signature{
    width: fit-content;
    border-bottom: 1px solid $black;

    img{
      display: block;
      object-fit: contain;
      object-position: center;
      height: 70px;
      user-select: none;
    }

    strong{
      padding-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }
}