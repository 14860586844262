.dashboard{
  background: none !important;

  .ant-layout-content{
    max-width: 1500px;
  }

  .dashboard-hero{
    width: 100%;
    border-radius: 3px;
    margin: 20px auto;
    padding: 80px 30px;
    text-align: center;

    h1{
      text-align: center;
      color: $white;
      font-size: 28px;
      line-height: 140%;
      max-width: 700px;
      margin: 0 auto;
    }

    p{
      color: $white;
      text-align: center;
      max-width: 700px;
      margin: 20px auto;
    }

    .ant-btn{
      margin: 10px auto;
    }
  }
}