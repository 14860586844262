.doc{
  background: #FAFAFA;
  margin: 0;
  padding: 0;
  font-size: 12pt;
  box-sizing: border-box;

  &.mini{
    font-size: 12px;
  }

  .page {
    width: 210mm;
    min-height: 297mm;
    padding: 0;
    margin: 0 auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;

    &.mini{
      width: 100%;
      height: 100%;
      min-height: unset;
    }

    .signature-box{
      display: flex;
      background: $white;
      z-index: 999;
      position: absolute;
      width: calc(100% - 20mm);
      height: 15mm;
      border: 1px solid $black;
      bottom: 5mm;
      left: 10mm;
      right: 10mm;
      margin: auto;
      justify-content: stretch;
      align-items: center;

      .signature-cell{
        width: 50%;
        height: 100%;
        border-right: 1px solid $black;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 5mm;
        padding-right: 5mm;

        &:nth-child(even){
          border: none;
        }

        .signature{
          display: block;
          height: 100%;
          object-fit: contain;
          object-position: center;
          margin: 0 10px;
        }
      }
    }

    .subpage {
      padding: 1cm;
      border: 5px red solid;
      height: 257mm;
      outline: 2cm #FFEAEA solid;
    }

    @page {
      size: A4;
      margin: 0;
    }

    @media print {
      html, body {
        width: 210mm;
        height: 297mm;        
      }
      .page {
          margin: 0;
          border: initial;
          border-radius: initial;
          width: initial;
          min-height: initial;
          box-shadow: initial;
          background: initial;
          page-break-after: always;
      }
    }
  }
}

.doc-modal{
  width: 100% !important;
  max-width: 600px !important;

  .item{
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    .item-title{
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      color: rgba($black, .7);
      display: block;
      margin: 0 0 5px 0;
    }
  
    h3{
      font-size: 16px;
      line-height: 130%;
      font-weight: 500;
      color: $black;
      margin: 0;
    }

    .btn{
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html, body {
      width: 210mm;
      height: 297mm;        
  }
  .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
  }
}